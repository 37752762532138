#root {
  min-height: 100%;
}

.MuiMenu-paper {
  max-height: 200px !important;
  border-bottom: 1px solid rgba(26, 18, 63, 0.5);
  border-left: 1px solid rgba(26, 18, 63, 0.5);
  border-right: 1px solid rgba(26, 18, 63, 0.5);
  border-radius: 5px;

  li {
    border-radius: 0 !important;
    border: none !important;
  }
}
